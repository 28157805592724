module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cottages of Paradise Point","short_name":"Cottages of Paradise Point","start_url":"/","background_color":"#6d5cb6","theme_color":"#ffffff","display":"standalone","icon":"src/assets/manifest-assets/favicon.svg","icons":[{"src":"/manifest-assets/favicon.svg","sizes":"192x192","type":"image/svg"},{"src":"/manifest-assets/apple-touch-icon-360x360.png","sizes":"384x384","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-4745938-48"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TPCLDMQ"},
    }]
